export const yieldCallback =
  <TEventHandler extends (...args: any[]) => void>(
    handler: TEventHandler,
    delay: number = 0,
  ) =>
  (...args: Parameters<TEventHandler>) => {
    if (typeof handler === 'function') {
      requestAnimationFrame(() => setTimeout(() => handler(...args), delay));
    }
  };
